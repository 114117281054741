/* @import url("https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap"); */
.profile-container {
  display: grid;
  grid-template-columns: 0.8fr 0.8fr;
  grid-template-rows: 0.3fr 1.7fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "nav-bar nav-bar"
    "description lotti-file"
    "profile-svg profile-svg";
  height: 90vh;
  background: #67abf4; /* fallback for old browsers */
  /* background: -webkit-linear-gradient(left, #aba9a5, #ffffff); */
  /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #aba9a5, #ffffff); */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* background: -moz-linear-gradient(left, #aba9a5, #ffffff); */

  position: relative;
  font-family: "Montserrat", sans-serif;
  color: white;
  z-index: 2;

}

.profile-container h1,
h2,
h3 {
  z-index: 2;
}

nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    ". . ."
    "name . links"
    ". . .";
  grid-area: nav-bar;
  background-color: #253138;
  position: fixed;
  width: 100%;
  /* top:0; */
  transition-timing-function: ease-in;
  transition: 0.5s;
  z-index: 3;
  border: solid thin;
}

.name {
  justify-self: center;
  grid-area: name;
  font-family: "Love Ya Like A Sister", cursive;
  font-size: 1.5em;
}

.links {
  justify-self: center;
  grid-area: links;
  display: flex;
  gap: 40px;
  font-size: 1.5em;
}

.links a {
  color: white;
}

.description {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.7fr 1.3fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
  grid-template-areas:
    "name-intro name-intro name-intro"
    "about-me about-me about-me"
    "profile-picture profile-picture profile-picture";
  grid-area: description;
  text-align: left;
}

.name-intro {
  grid-area: name-intro;
  text-align: left;
  display: flex;
  flex-flow: column;
  align-items: center;
  align-self: end;
  position: relative;
  left: 20vw;

  justify-self: start;
}
/* .name-intro h1{
  padding-right: 9vw;
} */

.about-me {
  grid-area: about-me;
  justify-self: start;
  line-height: 2em;
  text-align: left;
  /* position: relative;
  left: 4vw; */

  position: relative;
  left: 20vw;
}

.profile-picture {
  grid-area: profile-picture;
  z-index: 2;

  justify-self: start;
  position: relative;
  left: 20vw;
}

.profile-picture img {
  border-radius: 50%;
  border: solid thin;
  height: 14em;
}

.lotti-file,
.container {
  max-height: 300px;
  min-height: 200px;
  grid-area: lotti-file;
  justify-self: center;
  align-self: center;
  position: relative;
  right: 5vw;
  z-index: 1;
}

.profile-svg {
  grid-area: profile-svg;
  position: relative;
  z-index: 2;
  /* background-color: white; */
  margin-bottom: -1vh;
}

.custom-shape-divider-bottom-1640354007 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1640354007 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 224px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1640354007 .shape-fill {
  fill: #ffffff;
}

/* nav {
  height: 4em;
  position: sticky;
  top: 0;
  line-height: 4em;
  text-align: center;
  background-color: tomato;
  color: white;
  font-family: sans-serif;
  transition: transform 150ms ease-in-out;
} */

nav.nav-bar--hidden {
  transform: translateY(-100%);
}
