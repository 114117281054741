.cardContainer {
  display: block;
  border: solid;
}

$base-duration: 500ms;

// Colors
$color-1: #2c3e50;
$color-2: #1abc9c;
$color-3: #2ecc71;

// Breakpoints
$sm: new-breakpoint(min-width 320px);
$med: new-breakpoint(min-width 768px);
$lg: new-breakpoint(min-width 1024px);

figure {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 375px;
  height: 667px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    img {
      transform: scale(1.25);
    }
    figcaption {
      bottom: 0;
    }
  }
  h1 {
    position: absolute;
    top: 50px;
    left: 20px;
    margin: 0;
    padding: 0;
    color: black;
    font-size: 60px;
    font-weight: 100;
    line-height: 1;
  }
  img {
    height: 100%;
    transition: 0.25s;
  }
  figcaption {
    position: absolute;
    bottom: -33%;
    left: 0;
    width: 90%;
    //height: 50%;
    margin: 0;
    padding: 30px;
    background-color: rgba(black, 0.85);
    box-shadow: 0 0 20px rgba(black, 0.4);
    color: white;
    line-height: 1;
    transition: 0.25s;

    h3 {
      margin: 0 0 20px;
      padding: 0;
    }
    p {
      font-size: 14px;
      line-height: 1.75;
      width: 90%;
    }
    button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0;
      padding: 10px 30px;
      background-color: $color-2;
      border: none;
      border-radius: 5px;
      color: white;
      font-size: 14px;
	  cursor: pointer;
    }
  }

  a{
	  text-decoration: none;
	  color:white;
  }
}

.buttons {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	// width: 120%;
  }

  