/* .projects-container{
    padding-top: 20vh;
    padding-bottom: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width:  100vw;
} */

.projects-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "card-1 skills card-2"
    "card-3 skills card-4";
  padding-top: 20vh;
  padding-bottom: 10vh;
  width: 100vw;
  
}

.card-1 {
  justify-self: end;
  grid-area: card-1;
}

.card-2 {
  justify-self: start;
  align-self: center;
  grid-area: card-2;
}

.card-3 {
  justify-self: end;
  align-self: center;
  grid-area: card-3;
}

.card-4 {
  grid-area: card-4;
}

.skills {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "skills-circle skills-circle skills-circle"
    "skills-circle skills-circle skills-circle"
    "projects-lottie projects-lottie projects-lottie";
  grid-area: skills;
}

.skills-circle {
  grid-area: skills-circle;
  justify-self: center;

}
.projects-lottie {
  grid-area: projects-lottie;
}

