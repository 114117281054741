.App{
  overflow-x: hidden;
}

.construction{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  height: 50vh;
  padding-top:10vh;
  padding-bottom:10vh;
  overflow: hidden;
  
}

.lottie-construction{

transform: scale(1.5);
}

/* classes */

:root{
  --ff-bio: font-family: 'Love Ya Like A Sister', cursive;
  --test: font-family: 'Yuji Hentaigana Akari', cursive;
}

/* media screens */
/* mobiles */
@media only screen and (max-width: 600px) {
  nav{
    height: 8vh;
  }
  .profile-picture img{
    height: 10em;
    margin-top:-10em;
  }
 }
 /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  
  nav{
    height: 8vh;
    width: 100%;
  }

  .name{
    margin-left: 1vh;
  }

  .links{
    margin-right: 1vh;
  }

  .profile-container{
    height: 95vh;
  }

  

  .profile-picture img{
    height: 8em;
   
  }
 
  .description {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.7fr 1.3fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    justify-items: center;
    align-items: center;
    grid-template-areas:
      "name-intro name-intro name-intro"
      "about-me about-me about-me"
      "profile-picture profile-picture profile-picture";
    grid-area: description;
    text-align: left;
    margin-top:-10vh;
  }

  /* .description *{
    border:solid thin;
  } */
  
  .profile-picture {
    grid-area: profile-picture;
    z-index: 3;
    justify-self: start;
    position: unset;
    left: unset;
    padding-top: 10vh;
    padding-left: 4vw;
  }

  .description h1{
    font-size: 1em;
    right:16vw;
    position: relative;
  }

  .about-me {
    left: 4vw;
    font-size: .7em;
  }

  .lotti-file, .container {
  right:.5vw;
  bottom:10vh;
}

 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
  .profile-container {
    display: grid;
    grid-template-columns: 0.8fr 0.8fr;
    grid-template-rows: 0.3fr 1.7fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "nav-bar nav-bar"
      "description lotti-file"
      "profile-svg profile-svg";
    height: 90vh;
    background: #67abf4; /* fallback for old browsers */
    /* background: -webkit-linear-gradient(left, #aba9a5, #ffffff); */
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to right, #aba9a5, #ffffff); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    /* background: -moz-linear-gradient(left, #aba9a5, #ffffff); */
  
    position: relative;
    font-family: "Montserrat", sans-serif;
    color: white;
    z-index: 2;}
  
   nav{
     background-color: #253138;
     height: 6vh;
     width: 100%;
     z-index: 5;
   }

  

   .name{
    margin-left: 1vh;
  }

  .links{
    margin-right: 1vh;
  }

  .profile-container{
    height: 95vh;
  }

  .profile-picture {
    grid-area: profile-picture;
    z-index: 3;
    justify-self: start;
    position: unset;
    left: unset;
    padding-left: 4vw;
  }

  .profile-picture img{
    height: 10em;
  }
  
  .lotti-file{
    right:.5vw;
  }
 
  .description {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.7fr 1.3fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    justify-self: center;
    align-items: center;
    grid-template-areas:
      "name-intro name-intro name-intro"
      "about-me about-me about-me"
      "profile-picture profile-picture profile-picture";
    grid-area: description;
    text-align: left;
    margin-top:-10vh;
    /* margin-left: -8vw; */
    /* border:solid; */
  }
  

  .description h1{
    font-size: 1.4em;
    right:16vw;
    position: relative;
  }

  .about-me {
    left: 4vw;
    font-size: .9em;
  }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
   /* nav{
     background-color: yellow;
   } */

   .profile-picture img{
    height: 10em;
  }

  .profile-picture {
    grid-area: profile-picture;
    z-index: 3;
    justify-self: start;
    position: unset;
    left: unset;
    padding-left: 4vw;
  }


.description h1{
    font-size: 1.5em;
    right:16vw;
    position: relative;
  }

  .about-me {
    left: 4vw;
    font-size: .9em;
  }

 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
   /* nav{
   background-color: red;
 } */

 .description h1{
  font-size: 1.6em;}

 .about-me{
   font-size: 1em;
 }

}
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
   /* nav{
   background-color: green;
 } */
 .description h1{
  font-size: 1.7em;
  right:16vw;
  position: relative;
}

 .about-me{
   font-size: 1.1em;
 }

 .name{
  margin-left: 18vh;
}

.links{
  margin-right: 18vh;
}

} 